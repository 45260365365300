.control{
  padding: 20px 0;
  width: 100%;
}
.control label {
  color: #121212;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}
.inputDiv{
  position: relative;
}
.control input,
.select {
  /*max-width: 560px;*/
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 10px;
  border-radius: 0;
  height: 50px;
  border: 1px solid #D9D9D9;
}
.control input:focus{
  outline: none;
  /*color: #FFFFFF;*/
}
.control input::placeholder{
  color: #D9D9D9;
}
.invalid{
  border: 2px solid #CB2D25 !important;
}
.textDanger{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #CB2D25;
  margin-top: 4px;
  max-width: 520px;
  width: 100%;
}
.inputDiv img{
  width: 26px;
  position: absolute;
  top: 18px;
  right: 30px;
  cursor:pointer;
  height: 16px;
  object-fit: cover;
}
.redOne{
  color:#CB2D25;
  margin-left: 5px;
}
