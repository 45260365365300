.top {
  &_bar {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1E1E1E;
    color: #FFFFFF;
    border: 2px solid #8D8D8D;
    padding: 40px 32px;
    &__input {
      &_container {
        max-width: 460px;
        width: 100%;
        position: relative;
        & input {
          width: 100%;
          height: 52px;
          border-radius: 8px;
          border: 1px solid #787878;
          background: #0E0E0E;
          padding: 12px 100px 12px 45px;
          color: #8D8D8D;
        }
      }
      &_icon {
        position: absolute;
        top: 16px;
        left: 10px;
        width: 24px;
        height: 24px;
        background: url("/assets/icons/loop.svg");
        display: block;
      }
      &_btn {
        position: absolute;
        top: 6px;
        right: 10px;
        padding: 2px 16px;
        background: #479CFF;
        border-radius: 7px;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        border: none;
      }
    }
  }
}
