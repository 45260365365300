.main {
  &_container {
    width: 100%;
    height: 100vh;
    background: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
  }
  &_logo {
    font-family: "Texturina", serif;
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    &_box {
      margin-right: 24px;
      width: 54px;
      height: 54px;
      background: #479CFF;
      display: block;
    }
  }
  &_title {
    color: #fff;
  }
  &_btn {
    margin-top: 40px;
    padding: 10px 20px;
    background: #479CFF;
    border-radius: 7px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    border: none;
  }
}
