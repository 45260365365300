.invoice {
  &_left {
    &_bar {
      width: 100%;
      height: 100vh;
      background: #1E1E1E;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 45px 20px;
    }
  }
}
.top_logo {
  font-family: "Texturina", serif;
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 0 45px;
  margin-bottom: 30px;
  &_box {
    margin-right: 24px;
    width: 54px;
    height: 54px;
    background: #479CFF;
    display: block;
  }
}
.left_link {
  color: #fff;
  padding: 12px 50px;
  cursor: pointer;

}
.active {
  border-radius: 8px;
  background: #333333;
}
