.main {
  &_container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &_content {
    max-width: 560px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    & form {
      width: 100%;
    }

  }
  &_logo {
    font-family: "Texturina", serif;
    color: #121212;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-bottom: 140px;
    &_box {
      margin-right: 24px;
      width: 54px;
      height: 54px;
      background: #479CFF;
      display: block;
    }
  }
  &_title {
    color: #121212;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    border-bottom: 2px solid #479CFF;
    width: 100%;
    padding-bottom: 8px;
    text-align: center;
    margin-bottom: 60px;
  }
  &_input {
    &_item {
      padding: 20px 0;
      & label {
        color: #121212;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 6px;
      }
      & input {
        border-radius: 0;
        height: 50px;
        border: 1px solid #D9D9D9;
      }
    }
  }
  &_forgot {
    color: #479CFF;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;
    &_container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  &_btn {
    &_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0;
      & .btn_full {
        padding: 12px 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #479CFF;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        border: none;
      }
      & .btn_outline {
        padding: 12px 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: #686868;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        border: 3px solid #686868;

      }
    }
  }
  &_text {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 50px;
    &_link {
      color: #479CFF;
    }
  }
}
