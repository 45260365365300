.invoice {
  &_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
  }
  &_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    &_box {
      max-width: 740px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2px 25px;
      border-radius: 10px;
      border: 2px solid #1E1E1E;
      &.dark {
        background: #1E1E1E;
        & .invoice {
          &_head {
            &_text {
              color: #787878;
            }
            &_price {
              color: #FFF;
            }
          }
        }
      }
    }
    &_text {
      color: #787878;
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;
    }
    &_price {
      color: #1E1E1E;
      font-family: 'Roboto', sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0;
    }
    &_line {
      position: absolute;
      top: 10px;
      left: 12px;
      width: 4px;
      height: 115px;
      border-radius: 25px;
      background: #479CFF;
    }
  }
}

